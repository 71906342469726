import { instance } from '@/utils/http.js'

// 根据知识点科目获取章节
export const getcateGoryBySubject = function (params) {
  return instance({
    url: '/api/v1/public/category',
    method: 'get',
    params
  })
}

<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-form :model="form"
               label-width="80px">
        <el-col :span="6">
          <el-form-item label="科目">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       @change="getCategory"
                       clearable
                       placeholder="请选择">
              <el-option v-for="(item,index) in subjectList"
                         :key="item.subject_id +''+ index"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="类别">
            <el-select style="width:100%"
                       v-model="form.lecture_category_id"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in categoryList"
                         :key="item.lecture_category_id"
                         :label="item.lecture_category_name"
                         :value="item.lecture_category_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-form>

      <el-col :span="12">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button type="primary"
                   v-if="this.userInfo.is_research==1"
                   @click="add">新增</el-button>
      </el-col>
    </el-row>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import { getcateGoryBySubject } from '@/api/category.js'
export default {
  data () {
    return {
      userInfo: {},
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      subjectList: [],
      categoryList: [],
      form: {
        subject_id: '',
        lecture_category_id: '',
      },
      pageName: 'cz_teachingMaterials_admin_admin',
      tableColumns: [
        { prop: 'lecture_id', align: 'center', label: '编号', width: '100', showToopic: false },
        { prop: 'lecture_name', align: 'center', label: '讲义名', width: '', showToopic: true },
        { prop: 'lecture_description', align: 'center', label: '讲义描述', width: '', showToopic: false },
        { prop: 'lecture_category_name', align: 'center', label: '讲义类别名', width: '', showToopic: false },
        { prop: 'lecture_file', align: 'center', label: '讲义文件名', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '讲义科目', width: '', showToopic: false },
        { prop: 'lecture_questions', align: 'center', label: '讲义关联的题目编码', width: '', showToopic: false },
        { prop: 'sort', align: 'center', label: '排序', width: '', showToopic: false },
        // {
        //   prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
        // { content: '查看', type: 'look', event: 'look' },
        // { content: '修改', type: 'edit', event: 'edit' },
        // { content: '删除', type: 'del', event: 'delRow' },
        // ]
        // },
      ],
      handleOption: {
        prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
          { content: '查看', type: 'look', event: 'look' },
          { content: '修改', type: 'edit', event: 'edit' },
          { content: '删除', type: 'del', event: 'delRow' },
        ]
      },
      handleOptionSimple: {
        prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
          { content: '查看', type: 'look', event: 'look' },
        ]
      }
    }
  },
  components: {
    Form
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    if (this.userInfo.is_research == 1) {
      this.tableColumns.push(this.handleOption)
    } else {
      this.tableColumns.push(this.handleOptionSimple)
    }
    this.getSubject()
    this.getCategory()
    this.setTablesColums()
  },
  methods: {
    async getCategory () {
      this.form.lecture_category_id = ''
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getcateGoryBySubject(params)
      this.categoryList = data
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v1/lecture/lst',
        method: 'get',
        params: {
          page,
          limit,
          subject_id: this.form.subject_id,
          lecture_category_id: this.form.lecture_category_id
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/public/teacher_subject_lst',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/lecture/del',
          method: 'post',
          data: {
            lecture_id: row.lecture_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.search()
        }, 500);
      })
    },
    edit (row) {
      // let form = JSON.parse(JSON.stringify(row))
      let form = {
        lecture_id: row.lecture_id,
        lecture_name: row.lecture_name,
        lecture_category_id: row.lecture_category_id,
        filename: '',
        lecture_description: row.lecture_description,
        lecture_questions: row.lecture_questions,
        sort: row.sort
      }
      this.$refs.FormRef.form = form

      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.delFileRole()
      this.$refs.FormRef.getCategoryList()
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    add () {
      let form = {
        lecture_name: '',
        lecture_category_id: '',
        filename: '',
        lecture_description: '',
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.addFileRole()
      this.$refs.FormRef.getCategoryList()
      this.$refs.FormRef.dialogVisible = true
    },
    look (row) {
      window.open(row.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
